import {
  AnalyticsDataLayer,
  AnalyticsEventDataLayer,
  ErrorForAnalytics,
} from '../foundation/analytics/tealium/interfaces'

import { CancelToken } from 'axios'
import { ButtonProps } from './button'
export type Maybe<T> = T | null
export interface CtaStyle {
  [key: string]: ButtonProps['variant']
}

export interface CmsImageCrops {
  [key: string]: {
    [key: string]: {
      crop: string
      width: number
      height?: number
    }
  }
}

export interface CountryStates {
  callingCode: Maybe<string>
  code: Maybe<string>
  displayName: Maybe<string>
  states: Maybe<StatesProvinces[]>
}
export interface StatesProvinces {
  code: Maybe<string>
  displayName: Maybe<string>
}

export interface CountryStatesResponse {
  countries: Maybe<CountryStates[]>
  countryCodeStates: Maybe<StatesProvinces[]>
}

export interface PayloadBase {
  widget?: string
  cancelToken?: CancelToken
}

declare global {
  interface Window {
    utag: any
    utag_cfg_ovrd: any
    utag_data: AnalyticsDataLayer
    tealium_data2track: (AnalyticsEventDataLayer | ErrorForAnalytics)[]
    ApplePaySession
    applePayInstance
    monetateQ
    Trustpilot
    Klarna
    RXC
    frameAdvisor
    lazyLoadInstance
  }
}

export type CommonRequestPayloadProps = {
  cancelToken: CancelToken
  contractId: string
  currency: string
  forterToken: string
  langId: string
  responseFormat: string
  storeId: string
  profileName: string
  userAgent: string
  widget: string
}

type ServerError = {
  errorCode: string
  errorKey: string
  errorMessage: string
  errorParameters: string
}

export type ServerErrorResponse = {
  errors: ServerError[]
}

export interface BaseArgs {
  storeId: string
  langId: string
}

export interface IPayloadBasePromoCode {
  cancelToken?: CancelToken
  checkInventory?: boolean
  contractId?: string
  currency?: string
  storeId?: string
  widget?: string
  langId?: string
}

export interface IPayloadPromoCode {
  cancelToken?: CancelToken
  checkInventory?: boolean
  contractId?: string
  currency?: string
  storeId?: string
  widget?: string
  promoCode: string
}

export interface CtaFill {
  [key: string]: ButtonProps['filltype']
}

export interface ServiceLevelWrapper {
  serviceLevels: ServiceLevel[]
}

export interface ServiceLevel {
  // Unconventional naming of certain fields is due to BE dependency
  IsPickupRequired: string
  available: boolean
  sequence: number
  service_description: string
  service_type: string
}

export interface IdMeParameter {
  storeId: number
  orderId: string
  catalogId: string
  langId: number
  accessToken: string
}

export enum DeviceType {
  Mobile = 'mobile',
  Desktop = 'desktop',
  Tablet = 'tablet',
}
