import { CodIcon, JCBIcon } from '@components/UI/Icons/payments'
import { FC } from 'react'
import { CustomSVGIconProps } from '../components/UI/Icons/icon.type'
import storeUtil from '../utils/storeUtil'
import {
  IconAffirm,
  IconAmex,
  IconApplePay,
  IconBancontact,
  IconDiscover,
  IconIDeal,
  IconKlarna,
  IconMastercard,
  IconPaypal,
  IconVisa,
} from '@components/UI/Icons/VD/Payment'

const KEYS = {
  AFFIRM: 'AFFIRM',
  AMEX: 'AMEX',
  APPLE_PAY: 'APPLE_PAY',
  BANCONTACT: 'BANCONTACT',
  COD: 'COD',
  DISCOVER: 'DISCOVER',
  MASTERCARD: 'MASTERCARD',
  PAYPAL: 'PAYPAL',
  VISA: 'VISA',
  KLARNA: 'KLARNA',
  IDEAL: 'IDEAL',
  CREDIT: 'CREDIT',
  JCB: 'JCB',
  ZERO: 'ZERO',
} as const

type Key = keyof typeof KEYS
export type Icon = FC<CustomSVGIconProps>

const {
  AFFIRM,
  AMEX,
  APPLE_PAY,
  COD,
  DISCOVER,
  MASTERCARD,
  PAYPAL,
  VISA,
  KLARNA,
  JCB,
  ZERO,
  IDEAL,
  BANCONTACT,
  CREDIT,
} = KEYS

/**
 * @constant
 * Map of payment methods names that are fetched from server and stored at {@linkcode xStoreCfg.paymentMethods} string array.
 * Displayed at footer's "Payment methods" section.
 */
export const DISPLAY_NAMES = {
  [AFFIRM]: '? Affirm',
  [AMEX]: 'Amex',
  [BANCONTACT]: 'Bancontact',
  [APPLE_PAY]: 'ApplePay',
  [DISCOVER]: 'Discover',
  [MASTERCARD]: 'MasterCard',
  [PAYPAL]: 'PayPal',
  [VISA]: 'CreditCard',
  [KLARNA]: 'Klarna',
  [IDEAL]: 'Ideal',
  [CREDIT]: 'CyberSource',
} as const

/**
 * Type of payment method name. Equals to one of the strings from {@linkcode xStoreCfg.paymentMethods} array
 */
export type DisplayName = (typeof DISPLAY_NAMES)[keyof typeof DISPLAY_NAMES]

/**
 * @constant
 * Map of payment methods names fetched from usable_payment_info request ("paymentMethodName" property from "usablePaymentInfo" array)
 */
const CHECKOUT_NAMES = {
  [AFFIRM]: '? Affirm',
  [AMEX]: 'CyberSourceAmex',
  [APPLE_PAY]: 'ApplePay',
  [BANCONTACT]: 'Bancontact',
  [COD]: 'COD',
  [DISCOVER]: 'CyberSourceDiscover',
  [MASTERCARD]: 'CyberSourceMC',
  [PAYPAL]: 'PayPal',
  [VISA]: 'CyberSourceVisa',
  [KLARNA]: 'CyberSourceKlarna',
  [IDEAL]: 'Ideal',
  [CREDIT]: 'CyberSource',
} as const

/**
 * @constant
 * Map of payment methods names fetched payment instruction piMethodId
 */
const REVERSED_CHECKOUT_CYBERSOURCE_NAMES = {
  '? Affirm': AFFIRM,
  CyberSourceAmex: [AMEX],
  ApplePay: [APPLE_PAY],
  cod: [COD],
  CyberSourceDiscover: [DISCOVER],
  CyberSourceMC: [MASTERCARD],
  PayPal: [PAYPAL],
  CyberSourceVisa: [VISA],
  CyberSourceKlarna: [KLARNA],
} as const

/**
 * @constant
 * Map of all credit cards methods
 */
export const CHECKOUT_CC_NAMES: CheckoutCCName = [
  CHECKOUT_NAMES[AMEX],
  CHECKOUT_NAMES[DISCOVER],
  CHECKOUT_NAMES[MASTERCARD],
  CHECKOUT_NAMES[VISA],
]

/**
 * Type of payment method name. Equals to "paymentMethodName" property in "usablePaymentInfo" array (from server)
 */
export type CheckoutName = (typeof CHECKOUT_NAMES)[keyof typeof CHECKOUT_NAMES]

/**
 * Type of payment method name. Equals to "paymentMethodName" property in "usablePaymentInfo" array (from server)
 */
export type CheckoutCCName = CheckoutName[]

/**
 * @constant
 * Map of payment methods names that are fetched from server and stored at {@linkcode xStoreCfg?.paymentMethods} string array.
 * Displayed at footer's "Payment methods" section.
 */
const FOOTER_NAMES = {
  [AFFIRM]: '? Affirm',
  [AMEX]: 'Amex',
  [APPLE_PAY]: 'ApplePay',
  [BANCONTACT]: 'Bancontact',
  [IDEAL]: 'Ideal',
  [CREDIT]: 'CyberSource',
  [DISCOVER]: 'Discover',
  [MASTERCARD]: 'MasterCard',
  [PAYPAL]: 'PayPal',
  [VISA]: 'CreditCard',
  [KLARNA]: 'Klarna',
  [ZERO]: 'Zero',
} as const

/**
 * Type of payment method name. Equals to one of the strings from {@linkcode xStoreCfg?.paymentMethods} array
 */
export type FooterName = (typeof FOOTER_NAMES)[keyof typeof FOOTER_NAMES]

const ICONS: Record<Key, Icon | null> = {
  [AFFIRM]: IconAffirm,
  [AMEX]: IconAmex,
  [APPLE_PAY]: IconApplePay,
  [BANCONTACT]: IconBancontact,
  [COD]: CodIcon,
  [DISCOVER]: IconDiscover,
  [MASTERCARD]: IconMastercard,
  [PAYPAL]: IconPaypal,
  [VISA]: IconVisa,
  [KLARNA]: IconKlarna,
  [JCB]: JCBIcon,
  [IDEAL]: IconIDeal,
  [ZERO]: null,
  [CREDIT]: null,
} as const

const PAY_OPTIONS = { cod: 'COD', cc: 'CC' }

export const PAYMENT_METHODS = {
  CHECKOUT_NAMES,
  FOOTER_NAMES,
  DISPLAY_NAMES,
  ICONS,
  PAY_OPTIONS,
  CHECKOUT_CC_NAMES,
  REVERSED_CHECKOUT_CYBERSOURCE_NAMES,
  KEYS,
}

/**
 * @constant
 * Map of all errors code received from Cybersource
 */
export const SECURE_PAYMENT_CODES = {
  100: '100',
  101: '101', // DMISSINGFIELD
  102: '102', // DINVALIDDATA
  104: '104', // DDUPLICATE authorization request
  105: '105', // DDUPLICATE Merchant transaction identifier (MTI)
  110: '110', // SPARTIALAPPROVAL
  150: '150', // ESYSTEM General system failure
  151: '151', // ETIMEOUT The request was received but a server timeout occurred
  152: '152', // ETIMEOUT The request was received but a service did not finish running in time
  153: '153', // ESYSTEM Your account is not enabled for the OCT service
  154: '154', // ESYSTEM Bad MAC key
  200: '200', // DAVSNO Soft decline
  201: '201', // DCALL
  202: '202', // DCARDEXPIRED
  203: '203', // DCARDREFUSED general
  204: '204', // DCARDREFUSED Insufficient funds
  205: '205', // DCARDREFUSED Stolen or lost card.
  207: '207', // DCARDREFUSED Issuing bank unavailable.
  208: '208', // DCARDREFUSED Inactive card or card not authorized
  209: '209', // DCARDREFUSED Card verification number (CVN) did not match
  210: '210', // DCARDREFUSED The card has reached the credit limit
  211: '211', // DCARDREFUSED Invalid Card Verification Number (CVN)
  212: '212', // DCARDREFUSED EMV transaction was rejected
  213: '213', // DCARDREFUSED Fraud Status
  216: '216', // DCARDREFUSED Some of the card data was invalid
  220: '220', // DCHECKREFUSED Generic decline
  221: '221', // DCHECKREFUSED The customer matched an entry in the processor's negative file
  222: '222', // DCHECKREFUSED The customer's account is frozen
  230: '230', // DCV Soft decline - did not pass the Card Verification Number (CVN) check
  231: '231', // DINVALIDCARD Invalid account number
  232: '232', // DINVALIDDATA The card type is not accepted
  233: '233', // DINVALIDDATA General decline
  234: '234', // DINVALIDDATA merchant configuration problem
  235: '235', // DINVALIDDATA amount exceeds the originally authorized amount
  236: '236', // DINVALIDDATA Processor failure
  237: '237', // DINVALIDDATA already reversed
  238: '238', // DINVALIDDATA already settled
  239: '239', // DINVALIDDATA amount must match the previous transaction amount
  240: '240', // DINVALIDDATA The card type invalid or does not correlate with card number
  241: '241', // DINVALIDDATA referenced request ID is invalid
  242: '242', // DNOAUTH request ID is invalid
  243: '243', // DINVALIDDATA transaction was already settled or reversed
  244: '244', // DINVALIDACCOUNT Account number did not pass a verification check
  246: '246', // DNOTVOIDABLE already submitted to processor
  247: '247', // DINVALIDDATA capture was voided
  248: '248', // DBOLETODECLINED Boleto request was declined
  250: '250', // ETIMEOUT timeout occurred at processor
  251: '251', // DCARDREFUSED use frequency or maximum amount per use exceeded
  254: '254', // DINVALIDDATA Account is prohibited from processing stand-alone refunds
  255: '255',
  256: '256', // DINVALIDDATA Credit amount exceeded
  257: '257', // DINVALIDDATA Gift card account or prepaid card is already active
  259: '259', // DINVALIDDATA Reload limit exceeded
  260: '260', // DINVALIDDATA amount conflicts with the minimum or maximum amount allowed
  261: '261', // DINVALIDDATA merchant account set up invalid
  262: '262', // DINPROGRESS still in progress
  263: '263', // DCAPTUREPOSSIBLE Mass transit transaction (MTT) was declined
  264: '264', // DINVALIDDATA Processor missing field
  268: '268', // ETIMEOUT Transaction error
  400: '400', // DSCORE Fraud score exceeds threshold
  403: '403', // Communication error
  428: '428', // DAUTHENTICATE strong customer authentication (SCA) exemption was declined
  450: '450', // DINVALIDADDRESS Apartment number missing or not found
  451: '451', // DINVALIDADDRESS Insufficient address information
  452: '452', // DINVALIDADDRESS House/box number not found on street
  453: '453', // DINVALIDADDRESS Multiple address matches were found
  454: '454', // DINVALIDADDRESS P.O. Box identifier not found or out of range
  455: '455', // DINVALIDADDRESS Route service identifier not found or out of range
  456: '456', // DINVALIDADDRESS Street name not found in postal code
  457: '457', // DINVALIDADDRESS Postal code not found in database
  458: '458', // DINVALIDADDRESS Unable to verify or correct address
  459: '459', // DINVALIDADDRESS Multiple address matches were found (international)
  460: '460', // DINVALIDADDRESS Address match not found. No reason given
  461: '461', // DINVALIDADDRESS Unsupported character set
  465: '465', // DAUTHENTICATE Encountered a Payer Authentication problem. Payer could not be authenticated
  475: '475', // DAUTHENTICATE The cardholder is enrolled in Payer Authentication
  476: '476', // DAUTHENTICATIONFAILED Encountered a Payer Authentication problem. Payer could not be authenticated
  478: '478', // DAUTHENTICATE Strong customer authentication (SCA) is required
  480: '480', // DREVIEW The order is marked for review by Decision Manager
  481: '481', // DREJECT The order was rejected by Decision Manager
  490: '490', // DAGGDENIED Your aggregator or acquirer is not accepting transactions from you at this time
  491: '491', // DAGGREJECTED Your aggregator or acquirer is not accepting this transaction
  520: '520', // DSETTINGS approved by the issuing bank but was declined based on your Smart Authorization settings
  700: '700', // DRESTRICTED The customer matched the Denied Parties List
  701: '701', // DRESTRICTED Export bill_country/ship_country
  702: '702', // DRESTRICTED Export email_country
  703: '703', // DRESTRICTED Export hostname_country/ip_country match.
} as const

/**
 * List of codes with messages that can be displayed to the user
 */
export const SECURE_PAYMENT_CODES_FOR_ERROR_MESSAGE = {
  200: '200',
  202: '202',
  205: '205',
  207: '207',
  208: '208',
  231: '231',
  478: '478',
}

/**
 * Type of Cybersource codes
 */
export type SecurePaymentCode = (typeof SECURE_PAYMENT_CODES)[keyof typeof SECURE_PAYMENT_CODES]

/**
 * Type of Cybersource success codes
 */
export type SecurePaymentSuccessCode = SecurePaymentCode[]

/**
 * Type of Cybersource error codes
 */
export type SecurePaymentErrorCode = SecurePaymentCode[]

/**
 * @constant
 * Array of all Cybersource success codes
 */
export const SECURE_PAYMENT_SUCCESS_CODES: SecurePaymentSuccessCode = [
  SECURE_PAYMENT_CODES[100],
  SECURE_PAYMENT_CODES[475],
]

/**
 * @constant
 * Array of all Cybersource error codes
 */
export const SECURE_PAYMENT_ERROR_CODES: SecurePaymentErrorCode = Object.values(SECURE_PAYMENT_CODES).filter(
  c => !SECURE_PAYMENT_SUCCESS_CODES.includes(c)
)

/**
 * @constant
 * Array of codes with messages that can be displayed to the user
 */
export const SECURE_PAYMENT_CODES_FOR_ERROR_MESSAGE_KEYS = Object.keys(SECURE_PAYMENT_CODES_FOR_ERROR_MESSAGE)

/**
 * @constant
 * Payment columns names
 */

export const PAYMENT_COLUMNS = {
  LEFT: 'left',
  RIGHT: 'right',
  MOBILE: 'mobile',
}

/**
 * Checks if the entered expiration date is a valid value
 * @returns Whether the date is valid.
 */
export const isValidCardExpirationDate = (date: [number, number]) => {
  const [month, year] = date

  if (month > 12 || isNaN(month) || isNaN(year)) {
    return true
  }

  const inputDate = new Date(year, month)
  const currentDate = new Date()

  return inputDate.getTime() < currentDate.getTime()
}

/**
 * Checks if the entered cc holder name has value
 * @returns Whether the cc holder name is valid.
 */
export const isValidCardHolderName = (cardHolderName: string): boolean => {
  return cardHolderName.trim() !== '' && cardHolderName.trim().indexOf(' ') >= 0
}

/**
 * Checks if the entered cvv code entered is a numeric value
 * @returns Whether the cvv code format is valid.
 */
export const isValidCode = (cardCVC: string, cardType: string | null): boolean => {
  const isValidInput = cardCVC.trim() === '' || storeUtil.isNumeric(cardCVC.trim())
  const isValidCodeLength = cardType === CHECKOUT_NAMES['AMEX'] ? cardCVC.length === 4 : cardCVC.length === 3
  const isValidNoWhiteSpace = /^\S*$/.test(cardCVC)

  return isValidInput && isValidCodeLength && isValidNoWhiteSpace
}

export const isValidCVV = (cvv: string, options: { isAmex: boolean }): boolean => {
  const cardType = options.isAmex ? CHECKOUT_NAMES['AMEX'] : null
  return isValidCode(cvv, cardType)
}

/**
 * Key found as page fragment when re-directed back to the checkout from a failed transaction
 * from a third party payment provider
 *  */
export const PAYMENT_METHOD_FAILURE_KEY = 'paymentMethodError'
