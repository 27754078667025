import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgPaypal = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="#fff"
        d="M22.283 4H1.717C.77 4 0 4.86 0 5.92v12.16C0 19.14.769 20 1.717 20h20.566C23.23 20 24 19.14 24 18.08V5.92C24 4.86 23.231 4 22.283 4Z"
      />
      <path
        fill="#CCCFD9"
        fillRule="evenodd"
        d="M22.283 4.5H1.717C1.096 4.5.5 5.082.5 5.92v12.16c0 .838.596 1.42 1.217 1.42h20.566c.621 0 1.217-.582 1.217-1.42V5.92c0-.838-.596-1.42-1.217-1.42ZM1.717 4h20.566C23.23 4 24 4.86 24 5.92v12.16c0 1.06-.769 1.92-1.717 1.92H1.717C.77 20 0 19.14 0 18.08V5.92C0 4.86.769 4 1.717 4Z"
        clipRule="evenodd"
      />
      <g clipPath="url(#paypal_svg__clip0_278_1567)">
        <path
          fill="#253B80"
          d="M9.453 9.927H8.35a.153.153 0 0 0-.151.13l-.446 2.828a.092.092 0 0 0 .09.106h.527c.076 0 .14-.054.152-.13l.12-.762a.153.153 0 0 1 .151-.13h.35c.726 0 1.146-.351 1.255-1.048.05-.305.002-.544-.14-.712-.157-.184-.435-.282-.805-.282Zm.128 1.033c-.06.396-.363.396-.656.396H8.76l.117-.74a.092.092 0 0 1 .09-.077h.077c.2 0 .387 0 .484.114.058.068.076.168.054.307ZM12.75 10.948h-.527a.092.092 0 0 0-.091.077l-.024.148-.037-.054c-.114-.166-.369-.221-.623-.221-.584 0-1.083.442-1.18 1.062-.05.31.021.605.197.812.16.19.391.268.665.268.47 0 .731-.302.731-.302l-.023.147a.092.092 0 0 0 .09.106h.476c.076 0 .14-.055.152-.13l.285-1.807a.092.092 0 0 0-.09-.106Zm-.736 1.028a.589.589 0 0 1-.596.504c-.153 0-.275-.05-.354-.142a.449.449 0 0 1-.083-.371.591.591 0 0 1 .592-.509c.15 0 .272.05.352.144.08.095.112.227.09.374ZM15.564 10.948h-.531a.154.154 0 0 0-.127.067l-.732 1.078-.31-1.036a.154.154 0 0 0-.147-.11h-.522a.092.092 0 0 0-.087.122l.584 1.716-.55.776a.092.092 0 0 0 .076.145h.53c.05 0 .097-.024.126-.066l1.765-2.548a.092.092 0 0 0-.075-.144Z"
        />
        <path
          fill="#179BD7"
          d="M17.321 9.927h-1.103a.153.153 0 0 0-.151.13l-.446 2.828a.092.092 0 0 0 .09.106h.566a.107.107 0 0 0 .106-.09l.127-.802a.153.153 0 0 1 .151-.13h.35c.726 0 1.145-.351 1.255-1.048.05-.305.002-.544-.141-.712-.157-.184-.434-.282-.804-.282Zm.128 1.033c-.06.396-.363.396-.656.396h-.166l.117-.74a.092.092 0 0 1 .09-.077h.077c.199 0 .387 0 .484.114.058.068.076.168.054.307ZM20.618 10.948h-.527a.091.091 0 0 0-.091.077l-.023.148-.038-.054c-.114-.166-.369-.221-.623-.221-.584 0-1.082.442-1.18 1.062-.05.31.022.605.197.812.161.19.391.268.665.268.47 0 .732-.302.732-.302l-.024.147a.092.092 0 0 0 .091.106h.476c.075 0 .14-.055.15-.13l.287-1.807a.092.092 0 0 0-.092-.106Zm-.736 1.028a.588.588 0 0 1-.596.504c-.153 0-.276-.05-.355-.142a.45.45 0 0 1-.082-.371.591.591 0 0 1 .591-.509c.15 0 .272.05.353.144.08.095.113.227.09.374ZM21.241 10.005l-.453 2.88a.092.092 0 0 0 .091.106h.455c.076 0 .14-.054.152-.13l.446-2.828a.092.092 0 0 0-.09-.106h-.51a.092.092 0 0 0-.09.078Z"
        />
        <path
          fill="#253B80"
          d="m3.172 13.541.084-.536-.188-.004h-.897l.624-3.954a.05.05 0 0 1 .05-.043h1.513c.503 0 .85.104 1.03.31a.709.709 0 0 1 .165.31c.027.117.028.256.001.426l-.002.013v.109l.085.048a.595.595 0 0 1 .172.13.61.61 0 0 1 .14.313c.02.129.013.281-.02.454a1.597 1.597 0 0 1-.186.513c-.078.13-.177.239-.295.323-.112.08-.245.14-.396.179a1.98 1.98 0 0 1-.496.057H4.44a.357.357 0 0 0-.35.299l-.01.048-.148.944-.007.035c-.002.011-.005.017-.01.02a.025.025 0 0 1-.015.006h-.727Z"
        />
        <path
          fill="#179BD7"
          d="M5.717 10.075c-.004.03-.01.059-.015.089-.2 1.024-.882 1.378-1.754 1.378h-.444a.216.216 0 0 0-.213.183l-.227 1.441-.064.409a.114.114 0 0 0 .112.131h.787a.19.19 0 0 0 .187-.16l.008-.04.148-.94.01-.052a.19.19 0 0 1 .187-.16h.117c.763 0 1.36-.31 1.535-1.206.073-.374.035-.687-.158-.906a.754.754 0 0 0-.216-.167Z"
        />
        <path
          fill="#222D65"
          d="M5.509 9.992a1.57 1.57 0 0 0-.194-.043c-.12-.02-.251-.029-.392-.029H3.738a.188.188 0 0 0-.187.16l-.253 1.598-.007.047a.216.216 0 0 1 .213-.183h.444c.872 0 1.554-.354 1.754-1.378l.015-.089a1.07 1.07 0 0 0-.208-.083Z"
        />
        <path
          fill="#253B80"
          d="M3.55 10.08a.189.189 0 0 1 .188-.16h1.185a2.465 2.465 0 0 1 .492.048c.032.007.063.015.094.024l.045.014c.058.02.113.043.164.07.059-.38 0-.637-.206-.87-.225-.257-.632-.367-1.154-.367H2.845a.216.216 0 0 0-.213.182l-.63 3.995a.13.13 0 0 0 .128.15h.934l.234-1.488.253-1.598Z"
        />
      </g>
      <defs>
        <clipPath id="paypal_svg__clip0_278_1567">
          <path fill="#fff" d="M2 8.839h20v5.323H2z" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
export default SvgPaypal
