import { fetchUserContext } from '@features/context/thunks/fetchUserContext'
import { fetchContract } from '@features/contract/thunks/fetchContract'
import { fetchEntitledOrganization } from '@features/organization/thunks/fetchEntitledOrganization'
import { setGuestLoggedIn } from '@features/user/slice'
import guestIdentityService from '@foundation/apis/transaction/guestIdentity.service'
import { storageSessionHandler } from '@foundation/utils/storageUtil'
import { AppDispatch } from '@redux/store'
import { LoginIdentityResponse } from '@typesApp/account'
import { REGISTER_TYPES } from '@constants/user'
import { IContextSliceState } from '@features/context/IContextSliceState'

export const createUserIdentity = async (dispatch: AppDispatch, isRememberMeEnabled?: boolean) => {
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
  const payload = isRememberMeEnabled ? { updateCookies: true } : {}

  if (!currentUser) {
    const guestResponse = await guestIdentityService.login(payload)
    const guestResponseData = guestResponse.data as LoginIdentityResponse
    dispatch(setGuestLoggedIn(guestResponseData))
    dispatch(fetchContract(guestResponseData))
    dispatch(fetchEntitledOrganization(guestResponseData))
    dispatch(fetchUserContext(guestResponseData))
  }
}

export const evaluateIsPartiallyAuthenticated = (context: IContextSliceState): boolean => {
  const { isPartiallyAuthenticated, basicInfo } = context
  return isPartiallyAuthenticated && basicInfo?.registerType === REGISTER_TYPES.R
}

const getCurrentUserId = (currentUser: any, runAsId: string, callerId: string): string | undefined => {
  if (runAsId !== callerId) {
    return currentUser?.forUserId
  }
  return currentUser?.userId ?? currentUser?.details?.userId
}

export const currentUserHasWcCookies = (runAsId: string, callerId: string, isUserLoggedIn: boolean): boolean => {
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
  const currentUserId = getCurrentUserId(currentUser, runAsId, callerId)

  if (!currentUser || !isUserLoggedIn || runAsId === currentUserId) {
    return true
  }
  return false
}
