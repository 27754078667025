import { UserDetails } from '@typesApp/user'
import { LOGIN_REDIRECT_EVENT, NEW_REGISTRATION_REDIRECT_EVENT } from './constants/tracker'
import { getUserDetailsForAnalytics } from './lib'

export const getRedirectAnalyticsData = () => {
  const redirectAnalyticsData = getRedirectAnalyticsDataFromStorage()

  if (redirectAnalyticsData) {
    const loginPropertiesFromStorage = JSON.parse(redirectAnalyticsData)
    const { id: ignoredId, ...restProperties } = loginPropertiesFromStorage

    removeRedirectAnalyticsDataFromStorage()

    return restProperties
  }

  return {}
}

const getRedirectAnalyticsDataFromStorage = (): string | null => {
  const loginRedirectEvent = localStorage.getItem(LOGIN_REDIRECT_EVENT)
  const registrationRedirectEvent = localStorage.getItem(NEW_REGISTRATION_REDIRECT_EVENT)

  const redirectAnalyticsData = loginRedirectEvent || registrationRedirectEvent

  return redirectAnalyticsData
}

export const removeRedirectAnalyticsDataFromStorage = (): void => {
  localStorage.removeItem(LOGIN_REDIRECT_EVENT)
  localStorage.removeItem(NEW_REGISTRATION_REDIRECT_EVENT)
}

export const saveProcessedUserDetailsToLocalStorage = (userDetails: UserDetails): void => {
  const processedUserDetails = getUserDetailsForAnalytics(true, userDetails)

  localStorage.setItem(LOGIN_REDIRECT_EVENT, JSON.stringify({ ...processedUserDetails }))
}
