import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgConfianza = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path fill="#fff" d="M12 19.744a7.744 7.744 0 1 0 0-15.488 7.744 7.744 0 0 0 0 15.488Z" />
      <path
        fill="#684B99"
        fillRule="evenodd"
        d="M16.398 15.937h.228l-.114-.266-.114.266Zm.07-.75h.088l.5 1.088h-.279l-.063-.13h-.404l-.06.13h-.284l.502-1.087Zm-1.564.225v-.215h.79l.015.035-.46.828h.432v.215h-.815l-.02-.035.475-.828h-.417Zm-.623-.215h.253v1.078l-.057.016-.616-.566v.55h-.253v-1.077l.06-.016.613.562v-.546Zm-4.856 0 .06-.015.613.562v-.546h.253v1.077l-.057.016-.62-.566v.55h-.252l.003-1.077Zm-.62.36a.3.3 0 0 0-.523 0 .363.363 0 0 0-.045.18c0 .07.016.13.045.181a.295.295 0 0 0 .113.11.27.27 0 0 0 .149.042c.05 0 .1-.013.148-.041a.293.293 0 0 0 .114-.11.363.363 0 0 0 .044-.181.377.377 0 0 0-.044-.18Zm-.799.393a.544.544 0 0 1-.04-.215.57.57 0 0 1 .158-.395.557.557 0 0 1 .182-.12.619.619 0 0 1 .234-.044c.085 0 .164.015.234.044a.557.557 0 0 1 .303.3.543.543 0 0 1 .041.215.539.539 0 0 1-.16.395l-.01.009a.59.59 0 0 1-.174.114.619.619 0 0 1-.234.044.619.619 0 0 1-.234-.044.502.502 0 0 1-.183-.123.54.54 0 0 1-.117-.18Zm4.765-.762h.088l.5 1.087h-.278l-.064-.13h-.404l-.06.13h-.284l.502-1.087Zm.155.749-.114-.266-.114.266h.228Zm-1.179-.74H12v1.078h-.253v-1.077Zm-.37.642h-.334v.436h-.253v-1.077h.68v.214h-.427v.212h.335v.215Zm-4.48-.512a.52.52 0 0 1 .183-.11.717.717 0 0 1 .215-.042.574.574 0 0 1 .338.095c.035.025.063.05.082.07l-.155.183a.255.255 0 0 0-.05-.045.311.311 0 0 0-.215-.076.297.297 0 0 0-.269.161.326.326 0 0 0-.041.165c0 .06.013.113.035.164a.27.27 0 0 0 .107.123.323.323 0 0 0 .376-.019.321.321 0 0 0 .057-.05l.155.164c-.006.01-.016.015-.022.025a.502.502 0 0 1-.202.127.655.655 0 0 1-.206.031.58.58 0 0 1-.401-.158.47.47 0 0 1-.092-.114.565.565 0 0 1-.076-.293.505.505 0 0 1 .181-.401Z"
        clipRule="evenodd"
      />
      <path
        fill="#859EAF"
        fillRule="evenodd"
        d="m14.142 16.686.234-.004v1.23l-.054.015-.74-.746v.73h-.233v-1.229l.054-.016.736.746.003-.726Zm1.45.202h-.527v.303h.436v.199h-.436v.325h.546v.2h-.783v-1.23h.764v.203Zm-3.393.828v.199h-.74v-1.226h.234v1.026h.506Zm.405-1.03h.236v1.229h-.236v-1.23Zm-3.356.388a.396.396 0 0 0-.149-.148.378.378 0 0 0-.388 0 .387.387 0 0 0-.146.148.434.434 0 0 0-.057.228.45.45 0 0 0 .057.227.401.401 0 0 0 .342.2.405.405 0 0 0 .398-.427.435.435 0 0 0-.057-.228Zm-.006-.325a.63.63 0 0 1 .316.553.63.63 0 0 1-.316.553.699.699 0 0 1-.676 0 .627.627 0 0 1-.31-.553.66.66 0 0 1 .082-.322.693.693 0 0 1 .228-.231.663.663 0 0 1 .338-.086c.13 0 .24.029.338.086Zm.742-.067.054-.015.736.745v-.73l.234-.003v1.23l-.054.015-.74-.746v.73h-.233l.003-1.226Z"
        clipRule="evenodd"
      />
      <path
        fill="#684B99"
        d="M12.177 14.019h-.095a3.82 3.82 0 0 1-2.689-1.144 3.738 3.738 0 0 1-1.147-2.752 3.76 3.76 0 0 1 1.147-2.752 3.826 3.826 0 0 1 2.686-1.137h.006a.319.319 0 0 1 .007.638 3.156 3.156 0 0 0-2.25.951c-.644.641-.957 1.393-.957 2.297 0 .907.313 1.659.957 2.297a3.147 3.147 0 0 0 2.246.957h.086c.913 0 1.671-.313 2.316-.957.65-.638.967-1.39.967-2.297v-.038c0-.177.142-.319.319-.319.177 0 .319.142.319.319v.038a3.733 3.733 0 0 1-1.157 2.752 3.764 3.764 0 0 1-2.761 1.147Z"
      />
      <path
        fill="#C8D423"
        d="M12 20c-2.2 0-4.101-.787-5.656-2.344C4.79 16.1 4 14.199 4 12c0-2.2.787-4.101 2.344-5.656C7.9 4.79 9.801 4 12 4c2.2 0 4.101.787 5.656 2.344C19.21 7.9 20 9.801 20 12c0 2.2-.787 4.101-2.344 5.656C16.104 19.21 14.199 20 12 20Zm0-15.488c-2.057 0-3.839.74-5.296 2.192C5.248 8.161 4.512 9.94 4.512 12c0 2.057.74 3.839 2.192 5.296C8.161 18.752 9.94 19.488 12 19.488c2.057 0 3.839-.74 5.296-2.192 1.456-1.457 2.192-3.236 2.192-5.296 0-2.057-.74-3.839-2.192-5.296C15.841 5.248 14.06 4.512 12 4.512Z"
      />
      <path
        fill="#C8D423"
        d="M12.047 12.929h-.015a2.706 2.706 0 0 1-1.84-.822 2.713 2.713 0 0 1-.82-1.98c0-.772.274-1.438.82-1.985a2.708 2.708 0 0 1 1.84-.815c.05-.003.1-.003.148-.003.77 0 1.434.275 1.981.821a2.71 2.71 0 0 1 .822 1.94.321.321 0 0 1-.317.326h-.006a.32.32 0 0 1-.319-.316 2.084 2.084 0 0 0-.635-1.498 2.063 2.063 0 0 0-1.53-.632c-.037 0-.075 0-.11.003h-.006a2.071 2.071 0 0 0-1.419.629 2.07 2.07 0 0 0-.632 1.532c0 .604.209 1.103.632 1.53.402.4.863.61 1.419.635a.318.318 0 0 1-.013.635Z"
      />
      <path
        fill="#859EAF"
        d="M12.174 11.81c-.461 0-.863-.167-1.191-.492a1.632 1.632 0 0 1-.493-1.192c0-.46.164-.862.493-1.19a1.63 1.63 0 0 1 1.19-.494c.462 0 .863.164 1.192.493.329.329.493.73.493 1.191 0 .465-.168.863-.496 1.192a1.613 1.613 0 0 1-1.188.492Zm0-2.726c-.29 0-.534.1-.74.306a.999.999 0 0 0-.303.74c0 .29.101.53.307.736.205.205.448.306.739.306.29 0 .53-.1.736-.306a.99.99 0 0 0 .307-.74c0-.29-.102-.534-.307-.739a.999.999 0 0 0-.74-.303Z"
      />
    </SvgIcon>
  )
}
export default SvgConfianza
