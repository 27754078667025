import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgIDeal = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <rect width={24} height={16} y={4} fill="#fff" rx={2} />
      <path
        fill="#000"
        d="M3.008 4.206h9.44c6.407 0 8.863 3.528 8.863 7.979 0 5.336-3.43 8.014-8.863 8.014h-9.44zm.915.923v14.148h8.525c5.147 0 7.947-2.418 7.947-7.092 0-4.804-3.035-7.056-7.947-7.056z"
      />
      <path
        fill="#000"
        d="M5.067 13.225h2.729v4.897h-2.73zM6.431 12.468c.937 0 1.696-.766 1.696-1.71 0-.945-.76-1.71-1.696-1.71-.937 0-1.696.765-1.696 1.71 0 .944.76 1.71 1.696 1.71"
      />
      <path
        fill="#D50072"
        d="M13.519 11.558v.793h-1.945V9.168h1.883v.793H12.36v.368h1.037v.793H12.36v.436zm.34.793.951-3.184h1.119l.951 3.184h-.818l-.179-.616h-1.028l-.179.616zm1.226-1.408h.57l-.261-.9h-.048zm2.19-1.775h.787v2.39h1.165c-.32-4.337-3.703-5.274-6.779-5.274H9.17v2.885h.485c.885 0 1.434.605 1.434 1.578 0 1.005-.536 1.604-1.434 1.604H9.17v5.774h3.279c4.998 0 6.739-2.341 6.8-5.774h-1.973zm-8.105.794v1.596h.485c.337 0 .648-.098.648-.811 0-.696-.346-.785-.648-.785z"
      />
    </SvgIcon>
  )
}
export default SvgIDeal
