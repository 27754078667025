import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgAffirm = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="#fff"
        d="M22.283 4H1.717C.77 4 0 4.86 0 5.92v12.16C0 19.14.769 20 1.717 20h20.566C23.23 20 24 19.14 24 18.08V5.92C24 4.86 23.231 4 22.283 4Z"
      />
      <path
        fill="#060809"
        fillRule="evenodd"
        d="M10.379 12.313h-.76v-.29c0-.377.22-.486.412-.486.211 0 .375.092.375.092l.26-.576s-.263-.166-.74-.166c-.536 0-1.147.293-1.147 1.216v.21H7.506v-.29c0-.377.222-.486.413-.486.109 0 .255.025.376.092l.259-.576c-.155-.088-.403-.166-.74-.166-.536 0-1.146.293-1.146 1.216v.21H6.18v.63h.487v2.223h.838v-2.224H8.78v2.224h.84v-2.224h.76v-.63Zm-6.124 2.341c-.22 0-.33-.105-.33-.28 0-.321.37-.432 1.047-.501 0 .43-.3.781-.717.781Zm.291-2.425c-.483 0-1.04.222-1.341.456l.276.564c.242-.215.633-.4.987-.4.335 0 .52.11.52.33 0 .147-.122.222-.355.251-.867.11-1.547.343-1.547.993 0 .515.377.827.967.827.42 0 .795-.227.973-.528v.444h.784v-1.858c0-.767-.548-1.079-1.264-1.079Zm7.875.084v2.853h.839v-1.375c0-.653.407-.845.69-.845.111 0 .26.031.36.103l.152-.754a.982.982 0 0 0-.376-.066c-.432 0-.703.186-.882.564v-.48h-.784Zm4.984.417a1.17 1.17 0 0 1 .948-.5c.537 0 .98.323.98.964v1.972h-.838v-1.714c0-.378-.234-.535-.456-.535-.277 0-.555.247-.555.78v1.469h-.838v-1.708c0-.39-.22-.54-.46-.54-.266 0-.55.252-.55.78v1.468h-.84v-2.853h.81v.431c.141-.275.448-.515.892-.515.407 0 .747.184.907.501Zm-5.688 2.436h-.838v-2.853h.838v2.853Z"
        clipRule="evenodd"
      />
      <path
        fill="#0FA0EA"
        fillRule="evenodd"
        d="M15.768 8.333c-2.266 0-4.285 1.53-4.858 3.495h.821c.479-1.464 2.104-2.749 4.037-2.749 2.35 0 4.38 1.74 4.38 4.447 0 .608-.081 1.156-.235 1.64h.797l.008-.027c.13-.5.197-1.042.197-1.613 0-3.02-2.263-5.193-5.147-5.193Z"
        clipRule="evenodd"
      />
      <path
        fill="#CCCFD9"
        fillRule="evenodd"
        d="M22.283 4.5H1.717C1.096 4.5.5 5.082.5 5.92v12.16c0 .838.596 1.42 1.217 1.42h20.566c.621 0 1.217-.582 1.217-1.42V5.92c0-.838-.596-1.42-1.217-1.42ZM1.717 4h20.566C23.23 4 24 4.86 24 5.92v12.16c0 1.06-.769 1.92-1.717 1.92H1.717C.77 20 0 19.14 0 18.08V5.92C0 4.86.769 4 1.717 4Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  )
}
export default SvgAffirm
