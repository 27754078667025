import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { RootReducerState } from '../../redux/reducers'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'

export interface ICategoryArgs {
  id?: string[]
  identifier?: string
}

export interface ICategory {
  description: string
  id: string
  identifier: string
  links: any
  name: string
  parentCatalogGroupID: string
  resourceId: string
  sequence: string
  shortDescription: string
  storeID: string
  uniqueID: string
  longDescription?: string
  seo: {
    href: string
  }
  canonicalurl?: string
}

export interface ICategoryResponse<T> {
  contents: T[]
}

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/v2/',
  }),
  endpoints: builder => ({
    getCategory: builder.query<ICategory[], ICategoryArgs>({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const state = queryApi.getState() as RootReducerState
        const result = await fetchWithBQ({
          url: 'categories',
          method: 'get',
          queryParams: args || {},
          //body: body || {},
          extraParams: { siteContextKey: 'search' },
          pathParams: { storeId: state.site.currentSite?.storeID! },
        })

        return (result.data as any)
          ? { data: transformResponse(result.data) as ICategory[] }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
  }),
})

const transformResponse = (data: { contents: ICategory[] }) => {
  return data.contents ?? []
}

export const { useGetCategoryQuery } = categoryApi
