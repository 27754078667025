import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgApplePay = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <path
        fill="#fff"
        d="M22.283 4H1.717C.77 4 0 4.86 0 5.92v12.16C0 19.14.769 20 1.717 20h20.566C23.23 20 24 19.14 24 18.08V5.92C24 4.86 23.231 4 22.283 4Z"
      />
      <path
        fill="#CCCFD9"
        fillRule="evenodd"
        d="M22.283 4.5H1.717C1.096 4.5.5 5.082.5 5.92v12.16c0 .838.596 1.42 1.217 1.42h20.566c.621 0 1.217-.582 1.217-1.42V5.92c0-.838-.596-1.42-1.217-1.42ZM1.717 4h20.566C23.23 4 24 4.86 24 5.92v12.16c0 1.06-.769 1.92-1.717 1.92H1.717C.77 20 0 19.14 0 18.08V5.92C0 4.86.769 4 1.717 4Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        d="M6.55 9.612c.178-.215.304-.508.304-.804a.549.549 0 0 0-.011-.115c-.29.01-.638.192-.845.437-.163.185-.315.482-.315.778 0 .045.007.09.01.104a.425.425 0 0 0 .079.008c.26 0 .585-.175.778-.408Zm.204.47c-.434 0-.786.264-1.012.264-.24 0-.556-.249-.934-.249-.715 0-1.442.593-1.442 1.709 0 .697.267 1.43.6 1.905.286.4.535.73.894.73.356 0 .511-.237.952-.237.449 0 .549.23.942.23.389 0 .648-.356.893-.708.274-.405.39-.797.393-.816-.022-.007-.767-.311-.767-1.164 0-.737.585-1.067.619-1.093-.386-.556-.975-.57-1.138-.57ZM8.555 14.4h.956v-1.768h1.179c1.086 0 1.82-.719 1.82-1.786v-.008c0-1.07-.734-1.786-1.82-1.786H8.555V14.4Zm1.901-4.566c.686 0 1.086.367 1.086 1.008v.008c0 .64-.4 1.011-1.086 1.011h-.945V9.834h.945Zm3.566 4.633c.534 0 .964-.23 1.186-.623h.063v.556h.912v-2.768c0-.857-.586-1.36-1.627-1.36-.964 0-1.616.452-1.705 1.133l-.004.034h.871l.004-.015c.092-.267.367-.419.79-.419.496 0 .759.223.759.627v.348l-1.042.063c-.985.06-1.537.482-1.537 1.204v.008c0 .737.56 1.212 1.33 1.212Zm-.419-1.257v-.007c0-.334.241-.523.756-.556l.912-.06v.327c0 .482-.411.845-.967.845-.408 0-.7-.204-.7-.549Zm3.592 2.613c.896 0 1.36-.322 1.708-1.338l1.453-4.136h-.971l-.941 3.165h-.07l-.946-3.165H16.42l1.45 4.055-.049.185c-.1.367-.326.523-.704.523-.097 0-.2-.004-.274-.011v.707c.11.011.24.015.352.015Z"
      />
    </SvgIcon>
  )
}
export default SvgApplePay
