import { IOrderDetails, IOrderItem } from '../../../../types/order'
import {
  getBrand,
  getCLBrand,
  getFrameMaterial,
  getFrameShape,
  getFrameType,
  getFrontColor,
  getLensesColor,
  getLensesTreatment,
  getModelName,
  getProductType,
  getSoldOut,
} from '../../../../utils/productAttributes'
import { ProductForAnalytics, ProductForAnalyticsFields } from '../interfaces'

import { getLensType } from '@utils/productAttributesAlgolia'
import { IProduct, ProductAnalyticsRX, ProductTypesEnum } from '../../../../types/product'
import { getRxPrice } from '../../../../utils/rx'
import { getProductPriceByCustomerSegments } from '@components/common/UI/ProductPrice/utils/utils'
import { USER_SEGMENT_GUEST } from '@constants/common'
import { DISPLAY_NAMES } from '@constants/paymentMethods'

export const parseCatentriesForRX = (orderItems: IOrderItem[], products: IProduct[]): ProductAnalyticsRX[] => {
  // If there's an RX product, removes from the catEntries the lens and services objects (that are provided as a normal item)
  // Then, add to the single RX item (the frame), the total RX price.
  const RX_FRAME = orderItems?.find(i => !!i?.roxableServices?.length)!
  const FRAME_ID = RX_FRAME?.productId
  const SERVICES_IDS =
    RX_FRAME && RX_FRAME!.roxableServices && (RX_FRAME!.roxableServices!.map(s => s.productId) as string[])
  const FRAME_CATENTRIES = products.filter(f => f.id && !SERVICES_IDS?.includes(f.id)) as ProductAnalyticsRX[]
  const FRAME_CATENTRY = FRAME_CATENTRIES.find(f => f.id === FRAME_ID)
  const FRAME_CATENTRY_INDEX = FRAME_CATENTRIES.findIndex(f => f.id === FRAME_ID)
  const RX_PRICE = RX_FRAME && getRxPrice(RX_FRAME.roxableServices!, FRAME_CATENTRY?.x_offerpriceRx)
  FRAME_CATENTRIES[FRAME_CATENTRY_INDEX] = {
    ...FRAME_CATENTRIES[FRAME_CATENTRY_INDEX],
    rxPrice: RX_PRICE,
  }

  return FRAME_CATENTRIES
}

// TODO product got from addToCartEvent don't have prices
//TODO Fix Status when add to cart
export const formatProduct = (
  item: ProductAnalyticsRX,
  quantity?: number | string,
  customerSegments?: string[]
): ProductForAnalyticsFields => {
  // Log.info('ANALYTICS - Formatting product', item as any)
  let type = getProductType(item)
  type = type === ProductTypesEnum.ContactLensesAccessories ? ProductTypesEnum.Accessories : type

  const prices = !item.isFreeGift
    ? getProductPriceByCustomerSegments(item?.x_price, customerSegments || [USER_SEGMENT_GUEST])
    : {
        offerPrice: '0',
        listPrice: '0',
      }

  const productContext: ProductForAnalyticsFields = {
    Case: '',
    Cloth: '',
    Frame: '',
    Lens: '',
    ModelCode: '',
    Size: '',
    Status: getSoldOutWithDefault(item) || 'Available', // TODO
    OosOptions: '', // TODO
    Category: type.toUpperCase(),
    Type: item.rxPrice ? 'RX' : 'STD',
    LensType: !item.rxPrice || getLensType(item).toLowerCase() === 'non_prescription' ? 'PLANO' : 'RX',
    // Price: item.rxPrice ? `${item.rxPrice}` : prices?.amountOfDiscount?.toString() || '',
    Price: item.rxPrice ? `${item.rxPrice}` : prices?.offerPrice?.toString() || '',

    PriceFull: item.rxPrice ? `${item.rxPrice}` : prices?.listPrice?.toString() || '',
    Brand: getBrand(item) || getCLBrand(item),
    Sku: `${item.id}`,
    ModelName: getModelName(item),
    MoCo: `${item.name}`,
    LensColor: getLensesColor(item),
    LensTechnology: getLensesTreatment(item),
    FrameColor: getFrontColor(item),
    FrameTechnology: getFrameMaterial(item),
    Shape: getFrameShape(item),
    LensUPC: '',
    Visibility: 'Public',
    FrameType: getFrameType(item),
    Units: quantity?.toString() || '1',
    PerkCode: '',
    InsuranceAmount: '',
    InsuranceCode: '',
    Warranty: '',
    TaxRate: '',
    CancelledUnits: '',
    CancelledAmount: '',
    Badges: '', // TODO
  }

  return productContext
}

const getSoldOutWithDefault = (product: IProduct) => {
  const soldOutLabel = getSoldOut(product)
  return soldOutLabel?.toLowerCase() === 'none' || !soldOutLabel ? 'Available' : 'SoldOut'
}

export const formatProductForTYP = (orderItem: IOrderItem): Partial<ProductForAnalyticsFields> => ({
  TaxRate: orderItem.salesTax,
})

export const getProductsForAnalytics = (
  products: ProductAnalyticsRX[],
  customerSegments?: string[]
): ProductForAnalytics => {
  return products.reduce((acc: ProductForAnalytics, p: ProductAnalyticsRX) => {
    if (p.id) {
      const type = getProductType(p)
      const id = type === ProductTypesEnum.ContactLenses ? p.name : p.id
      acc[id] = {
        ...formatProduct(p, p.quantity, customerSegments),
      }
    }

    return acc
  }, {})
}

export const getProductsWithQuantityForAnalytics = (products: IProduct[], orderItems: IOrderItem[]): IProduct[] => {
  return products.map(productItem => {
    const filteredOrderItems = orderItems.filter(orderItem => orderItem.xitem_display_catentry_id === productItem.id)

    const totalQuantity = filteredOrderItems.reduce((acc, currentValue) => {
      return acc + parseInt(currentValue.quantity as string)
    }, 0)

    const isFreeGift = filteredOrderItems[0]?.freeGift === 'true'

    return {
      ...productItem,
      quantity: `${totalQuantity || '1'}`,
      isFreeGift,
    }
  })
}

/** Products formatter for cart-related analytics events.
 * @returns map with unique `string` id as key and `ProductForAnalyticsFields` as value
 */
export const getProductsInCartForAnalytics = (
  products: IProduct[],
  productIdsCountInOrderMap: Record<string, number>
): ProductForAnalytics => {
  return products.reduce((acc: ProductForAnalytics, product) => {
    if (product && product.id) {
      if (productIdsCountInOrderMap[product.id] === 0) return acc
      const type = getProductType(product)
      const id = type === ProductTypesEnum.ContactLenses ? product.name : product.id

      acc[id] = {
        ...formatProduct(product, productIdsCountInOrderMap[product.id]),
      }
    }

    return acc
  }, {})
}

export const getProductsForTYPAnalytics = (products: IProduct[], orderDetails: IOrderDetails): ProductForAnalytics => {
  const orderItems = orderDetails.orderItem || []
  const productIdsCountInOrderMap: Record<string, number> = products.reduce(
    (productIdsCountInOrder, product: IProduct) => {
      const currentOrders = orderItems.filter(
        ({ xitem_display_catentry_id }) => xitem_display_catentry_id === product.id
      )

      const totalQuantity = currentOrders.reduce((acc, currentValue) => {
        return acc + parseInt(currentValue.quantity as string)
      }, 0)

      return product.id ? { ...productIdsCountInOrder, [product.id]: totalQuantity } : {}
    },
    {}
  )

  const formattedProducts = getProductsInCartForAnalytics(products, productIdsCountInOrderMap)

  for (const orderItemId in formattedProducts) {
    const currentFormattedProduct = formattedProducts[orderItemId]
    const orderItem = orderItems.find(i => i.orderItemId === orderItemId)

    if (orderItem) {
      formattedProducts[orderItemId] = {
        ...currentFormattedProduct,
        ...formatProductForTYP(orderItem),
      }
    }
  }

  return formattedProducts
}

export const getOrderTotalDiscount = (orderDetails: IOrderDetails): string => {
  const totalDiscount = orderDetails.adjustment?.reduce((acc, adj) => {
    if (adj.usage === 'Discount') {
      return acc + Number(adj.amount)
    }
    return acc
  }, 0)
  return String(totalDiscount)
}

export const getOrderDiscountNames = (orderDetails: IOrderDetails): string[] => {
  if (!orderDetails || !orderDetails?.adjustment) {
    return []
  }

  return orderDetails.adjustment.filter(adj => adj && adj?.code).map(adj => adj.code)
}

export const getOrderState = (orderDetails: IOrderDetails): string => orderDetails?.paymentInstruction?.[0]?.country

export const getOrderZipCode = (orderDetails: IOrderDetails): string => orderDetails?.paymentInstruction?.[0]?.zipCode

export const getOrderPaymentType = (orderDetails: IOrderDetails): string => {
  const paymentInstruction = orderDetails?.paymentInstruction?.[0]
  let paymentType = (paymentInstruction?.piDescription || '').replace(/\s/g, '')
  if (paymentInstruction?.payMethodId === DISPLAY_NAMES.BANCONTACT) {
    paymentType = paymentInstruction?.payMethodId
  }
  return paymentType
}

export const removeXPrefixes = obj => {
  if (Array.isArray(obj)) {
    return obj.map(removeXPrefixes)
  } else if (obj && typeof obj === 'object') {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const newKey = key.replace(/^x_/, '')
      const newValue = typeof value === 'string' ? value.replace(/^x_/, '') : removeXPrefixes(value)
      acc[newKey] = newValue
      return acc
    }, {})
  }
  return obj
}
