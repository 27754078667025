export { default as IconCvvAmex } from './CvvAmex'
export { default as IconCvvStandard } from './CvvStandard'
export { default as IconAffirm } from './Affirm'
export { default as IconAmex } from './Amex'
export { default as IconApplePay } from './ApplePay'
export { default as IconBancontact } from './Bancontact'
export { default as IconConfianza } from './Confianza'
export { default as IconDiscover } from './Discover'
export { default as IconIDeal } from './IDeal'
export { default as IconKlarna } from './Klarna'
export { default as IconMaestro } from './Maestro'
export { default as IconMastercard } from './Mastercard'
export { default as IconPaypal } from './Paypal'
export { default as IconVisa } from './Visa'
