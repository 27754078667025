import { IFormField } from '../types/form'
import { PersonalInfoFormData, RegistrationFormData, SettingsFormData, RegisterType } from '../types/user'

export const PERSONAL_INFO_FORM_FIELDS: IFormField<keyof PersonalInfoFormData>[] = [
  {
    fieldName: 'firstName',
    mandatory: true,
    validation: new RegExp(/^[a-zA-Z\s]*$/),
  },
  { fieldName: 'lastName', mandatory: true },
  {
    fieldName: 'phone1',
    mandatory: false,
    validation: new RegExp(/^(?:\+?(\d{1,3}))?([-. (]?(\d{3})[-. )]*)?((\d{3})[-. ]?(\d{2,4})(?:[-. ]?(\d{1,5}))?)$/gm),
  },
  { fieldName: 'preferredLanguage', mandatory: false },
  { fieldName: 'preferredCurrency', mandatory: false },
  { fieldName: 'email1', mandatory: true },
]

export const REGISTRATION_FORM_FIELDS: IFormField<keyof RegistrationFormData>[] = [
  {
    fieldName: 'firstName',
    mandatory: true,
    validation: new RegExp(/^[a-zA-Z\s]*$/),
  },
  { fieldName: 'lastName', mandatory: true },
  { fieldName: 'email', mandatory: true },
  { fieldName: 'email2', mandatory: true },
  { fieldName: 'dateOfBirth', mandatory: false },
  { fieldName: 'newPassword', mandatory: true },
  { fieldName: 'confirmPassword', mandatory: true },
  { fieldName: 'privacy1', mandatory: true },
  { fieldName: 'privacy2', mandatory: false },
]

export const SETTINGS_FORM_FIELDS: IFormField<keyof SettingsFormData>[] = [
  { fieldName: 'currentPassword', mandatory: true },
  { fieldName: 'newPassword', mandatory: true },
  { fieldName: 'confirmPassword', mandatory: true },
]

export const REGISTER_TYPES: Record<RegisterType, RegisterType> = {
  R: RegisterType.R,
  G: RegisterType.G,
}

export const UNKNOWN_USER_WC_ID = '-1002'
