import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgCvvAmex = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 64 40" {...other}>
      <g id="Group 25">
        <path
          id="Rectangle 270"
          d="M0 8C0 3.58172 3.58172 0 8 0H56C60.4183 0 64 3.58172 64 8V32C64 36.4183 60.4183 40 56 40H8C3.58172 40 0 36.4183 0 32V8Z"
          fill="white"
        />
        <path
          id="Rectangle 270 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56 1.33333H8C4.3181 1.33333 1.33333 4.3181 1.33333 8V32C1.33333 35.6819 4.3181 38.6667 8 38.6667H56C59.6819 38.6667 62.6667 35.6819 62.6667 32V8C62.6667 4.3181 59.6819 1.33333 56 1.33333ZM8 0C3.58172 0 0 3.58172 0 8V32C0 36.4183 3.58172 40 8 40H56C60.4183 40 64 36.4183 64 32V8C64 3.58172 60.4183 0 56 0H8Z"
          fill="#9399AD"
        />
        <g id="XXXX XXXX">
          <path
            id="Vector"
            d="M58.4979 30.6656H56.8145L55.1976 28.0358L53.5808 30.6656H52.002L54.3083 27.08L52.1494 23.7131H53.7757L55.2737 26.2145L56.7431 23.7131H58.3314L56.1487 27.1608L58.4979 30.6656Z"
            fill="#000F42"
          />
          <path
            id="Vector_2"
            d="M52.0018 30.6656H50.3184L48.7015 28.0358L47.0847 30.6656H45.5059L47.8122 27.08L45.6533 23.7131H47.2796L48.7776 26.2145L50.247 23.7131H51.8353L49.6526 27.1608L52.0018 30.6656Z"
            fill="#000F42"
          />
          <path
            id="Vector_3"
            d="M45.5057 30.6656H43.8223L42.2054 28.0358L40.5886 30.6656H39.0098L41.3162 27.08L39.1572 23.7131H40.7835L42.2815 26.2145L43.7509 23.7131H45.3392L43.1565 27.1608L45.5057 30.6656Z"
            fill="#000F42"
          />
          <path
            id="Vector_4"
            d="M39.0096 30.6656H37.3262L35.7093 28.0358L34.0925 30.6656H32.5137L34.8201 27.08L32.6611 23.7131H34.2875L35.7854 26.2145L37.2548 23.7131H38.8432L36.6604 27.1608L39.0096 30.6656Z"
            fill="#000F42"
          />
          <path
            id="Vector_5"
            d="M29.9832 30.6656H28.2998L26.683 28.0358L25.0661 30.6656H23.4873L25.7937 27.08L23.6347 23.7131H25.2611L26.759 26.2145L28.2285 23.7131H29.8168L27.634 27.1608L29.9832 30.6656Z"
            fill="#000F42"
          />
          <path
            id="Vector_6"
            d="M23.4881 30.6656H21.8047L20.1878 28.0358L18.571 30.6656H16.9922L19.2986 27.08L17.1396 23.7131H18.766L20.2639 26.2145L21.7334 23.7131H23.3217L21.1389 27.1608L23.4881 30.6656Z"
            fill="#000F42"
          />
          <path
            id="Vector_7"
            d="M16.992 30.6656H15.3086L13.6917 28.0358L12.0749 30.6656H10.4961L12.8025 27.08L10.6435 23.7131H12.2699L13.7678 26.2145L15.2373 23.7131H16.8256L14.6428 27.1608L16.992 30.6656Z"
            fill="#000F42"
          />
          <path
            id="Vector_8"
            d="M10.4959 30.6656H8.8125L7.19565 28.0358L5.5788 30.6656H4L6.30639 27.08L4.14742 23.7131H5.77378L7.27174 26.2145L8.74117 23.7131H10.3295L8.14674 27.1608L10.4959 30.6656Z"
            fill="#000F42"
          />
        </g>
        <g id="Group 23">
          <circle id="Ellipse 5" cx="48" cy="16" r="15.5" fill="white" stroke="#0137EF" />
          <g id="1234">
            <path
              d="M39.8589 19.8935H38.2142V15.3906C38.2142 15.2664 38.216 15.1138 38.2196 14.9328C38.2231 14.7483 38.2284 14.5602 38.2355 14.3686C38.2426 14.1734 38.2497 13.9978 38.2568 13.8417C38.2178 13.8878 38.1379 13.9676 38.0173 14.0812C37.9002 14.1912 37.7902 14.2905 37.6873 14.3792L36.7931 15.0978L36 14.1078L38.507 12.1118H39.8589V19.8935Z"
              fill="black"
            />
            <path
              d="M47.4704 19.8935H42.0306V18.7492L43.984 16.7745C44.3779 16.3699 44.6937 16.0328 44.9315 15.7631C45.1728 15.4899 45.3466 15.2415 45.4531 15.018C45.5631 14.7944 45.6181 14.5549 45.6181 14.2994C45.6181 13.9907 45.5312 13.76 45.3573 13.6075C45.187 13.4513 44.9581 13.3733 44.6707 13.3733C44.369 13.3733 44.0763 13.4424 43.7924 13.5808C43.5085 13.7192 43.2122 13.9162 42.9035 14.1717L42.0093 13.1124C42.2329 12.9208 42.4688 12.7399 42.7172 12.5695C42.9692 12.3992 43.2601 12.2626 43.5902 12.1597C43.9237 12.0532 44.3229 12 44.7878 12C45.2987 12 45.737 12.0923 46.1025 12.2768C46.4715 12.4613 46.7554 12.7132 46.9541 13.0326C47.1528 13.3484 47.2522 13.7068 47.2522 14.1078C47.2522 14.5371 47.167 14.9293 46.9967 15.2841C46.8263 15.6389 46.578 15.9902 46.2515 16.338C45.9286 16.6857 45.5383 17.0707 45.0805 17.493L44.0798 18.4351V18.5096H47.4704V19.8935Z"
              fill="black"
            />
            <path
              d="M53.4052 13.8523C53.4052 14.2107 53.3307 14.5212 53.1816 14.7838C53.0326 15.0464 52.8303 15.261 52.5749 15.4278C52.3229 15.5946 52.039 15.717 51.7232 15.7951V15.827C52.3478 15.9051 52.8215 16.0967 53.1444 16.4019C53.4708 16.707 53.6341 17.1151 53.6341 17.6261C53.6341 18.0803 53.5223 18.4866 53.2987 18.845C53.0787 19.2034 52.7381 19.4855 52.2768 19.6913C51.8155 19.8971 51.2211 20 50.4937 20C50.0643 20 49.6633 19.9645 49.2908 19.8935C48.9217 19.8261 48.574 19.7214 48.2475 19.5795V18.1796C48.5811 18.35 48.9306 18.4795 49.2961 18.5682C49.6616 18.6534 50.0022 18.6959 50.318 18.6959C50.9071 18.6959 51.3187 18.5948 51.5529 18.3925C51.7906 18.1867 51.9095 17.8993 51.9095 17.5303C51.9095 17.3138 51.8545 17.1311 51.7445 16.982C51.6345 16.833 51.4429 16.7195 51.1697 16.6414C50.9 16.5633 50.5221 16.5243 50.0359 16.5243H49.4451V15.2628H50.0466C50.5256 15.2628 50.8893 15.2185 51.1377 15.1297C51.3897 15.0375 51.56 14.9133 51.6487 14.7572C51.741 14.5975 51.7871 14.4165 51.7871 14.2142C51.7871 13.9375 51.7019 13.721 51.5316 13.5649C51.3613 13.4087 51.0774 13.3307 50.68 13.3307C50.4316 13.3307 50.2045 13.3626 49.9987 13.4265C49.7964 13.4868 49.6137 13.5613 49.4504 13.65C49.2872 13.7352 49.1435 13.8186 49.0193 13.9002L48.2582 12.7665C48.5633 12.5465 48.9199 12.3637 49.328 12.2182C49.7396 12.0727 50.2293 12 50.7971 12C51.599 12 52.2342 12.1615 52.7026 12.4844C53.171 12.8073 53.4052 13.2633 53.4052 13.8523Z"
              fill="black"
            />
            <path
              d="M60.1224 18.2808H59.1856V19.8935H57.5782V18.2808H54.2568V17.1364L57.6687 12.1118H59.1856V17.0033H60.1224V18.2808ZM57.5782 17.0033V15.6833C57.5782 15.5591 57.58 15.4118 57.5835 15.2415C57.5906 15.0712 57.5977 14.9009 57.6048 14.7305C57.6119 14.5602 57.619 14.4094 57.6261 14.2781C57.6367 14.1433 57.6438 14.0492 57.6474 13.996H57.6048C57.5374 14.1415 57.4664 14.2834 57.3919 14.4218C57.3174 14.5567 57.2304 14.6986 57.1311 14.8476L55.7046 17.0033H57.5782Z"
              fill="black"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
export default SvgCvvAmex
