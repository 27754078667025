import * as Sentry from '@sentry/nextjs'

export const getIsUrlIgnored = (requestUrl: string) => {
  if (!requestUrl) return false

  const ignoredUrlsRegexp = [/^\/wcs\/resources\/store\/\d+\/customersegments$/]
  return ignoredUrlsRegexp.find(regexpItem => requestUrl.match(regexpItem))
}

export const getIsDomainIgnored = (requestUrl: string) => {
  const ignoredDomainsRegexp = [/www-vdeprodlivessr\.luxgroup\.net/, /www-/]
  if (!requestUrl) return false

  return ignoredDomainsRegexp.find(regexpItem => requestUrl.match(regexpItem))
}

export const getIsErrorIgnored = (event: Sentry.ErrorEvent, hint: Sentry.EventHint) => {
  const errorMessage = event?.message ?? (hint.originalException as Error)?.message
  if (!errorMessage) return false
  const ignoredErrorMessagesRegex = [/.*undefined is not an object.*@context/]
  return ignoredErrorMessagesRegex.some(regex => regex.test(errorMessage))
}

export const getCustomErrorEventName = (event, newTitle, newMessage) => {
  const customException = {
    values: event.exception?.values?.map(eventItem => ({
      ...eventItem,

      value: newMessage,
      type: newTitle,
    })),
  }
  return customException
}
